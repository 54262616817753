import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import sanityClient from './sanityClient'; // Sanity kliens importálása
import './MiniArticles.css'; // A stílusok importálása

function MiniArticles() {
  const [miniArticles, setMiniArticles] = useState([]);

  useEffect(() => {
    // Mini cikkek lekérése egyszer az oldal betöltésekor
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    sanityClient
      .fetch(
        `*[_type == "post" && publishedAt >= $oneWeekAgo]{
          _id, title, slug, mainImage{asset->{_id, url}}, publishedAt
        } | order(publishedAt desc)`,
        { oneWeekAgo: oneWeekAgo.toISOString() }
      )
      .then((data) => {
        const shuffledPosts = data.sort(() => 0.5 - Math.random());
        setMiniArticles(shuffledPosts.slice(0, 6)); // Beállítjuk az első 6 véletlenszerű cikket
      })
      .catch((error) => console.error('Error fetching mini articles:', error));
  }, []); // Az useEffect csak az oldal betöltésekor fut le

  // Helper function to truncate titles to 150 characters
  const truncateTitle = (title) => {
    return title.length > 100 ? title.substring(0, 100) + '...' : title;
  };

  return (
    <aside className="mini-articles">
      <h2>News</h2>
      <div className="mini-article-grid">
        {miniArticles.length > 0 ? (
          miniArticles.map((post) => (
            <Link to={`/post/${post.slug.current}`} key={post._id} className="mini-article-link">
              <div className="mini-article">
                {post.mainImage && post.mainImage.asset?.url && (
                  <img src={post.mainImage.asset.url} alt={post.title} />
                )}
                <h3>{truncateTitle(post.title)}</h3> {/* Rövidítjük a címet 150 karakterre */}
              </div>
            </Link>
          ))
        ) : (
          <p>No recent posts available</p> // Ha nincs elérhető cikk
        )}
      </div>
    </aside>
  );
}

export default MiniArticles;
