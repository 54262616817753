// AddExtraFeatures.js
import React, { useState } from 'react';
import { ethers } from 'ethers';
import { TOKEN_FEATURES_ADDRESS } from '../contractConfig';  // Importáljuk a szerződés címét
import TokenFeaturesABI from '../contracts/TokenFeatures.json';  // A szerződés ABI-jának importálása

const AddExtraFeatures = () => {
  const [tokenAddress, setTokenAddress] = useState('');
  const [twitter, setTwitter] = useState('');
  const [telegram, setTelegram] = useState('');
  const [youtube, setYoutube] = useState('');
  const [category, setCategory] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');

  const handleAddFeatures = async (e) => {
    e.preventDefault();

    console.log("Adding extra features...");

    if (!window.ethereum) {
      alert('MetaMask is required to continue.');
      return;
    }

    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log("MetaMask account connected");

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Hozzáférünk a TokenFeatures okosszerződéshez
      const contract = new ethers.Contract(TOKEN_FEATURES_ADDRESS, TokenFeaturesABI.abi, signer);

      console.log("Smart contract instance created");

      // ETH érték küldése a tranzakcióban
      const tx = await contract.addTokenFeatures(
        tokenAddress, 
        twitter, 
        telegram, 
        youtube, 
        website, 
        category, 
        description,
        {
          value: ethers.utils.parseEther("0.002"),  // 0.002 ETH küldése
        }
      );

      console.log("Transaction sent:", tx);

      await tx.wait();  // Várakozás a tranzakció megerősítésére
      console.log("Transaction confirmed");
      alert("Extra features added successfully!");
    } catch (error) {
      console.error("Error adding features:", error);
      alert("Failed to add features. See console for details.");
    }
  };

  return (
    <div className="AddExtraFeatures">
      <h1>Add Extra Features to Your Token</h1>
      <form onSubmit={handleAddFeatures}>
        <div className="form-group">
          <label>Token Address:</label>
          <input
            type="text"
            className="form-control"
            value={tokenAddress}
            onChange={(e) => setTokenAddress(e.target.value)}
            placeholder="Enter your token address"
            required
          />
        </div>
        <div className="form-group">
          <label>Twitter (optional):</label>
          <input
            type="text"
            className="form-control"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
            placeholder="Enter your Twitter handle"
          />
        </div>
        <div className="form-group">
          <label>Telegram (optional):</label>
          <input
            type="text"
            className="form-control"
            value={telegram}
            onChange={(e) => setTelegram(e.target.value)}
            placeholder="Enter your Telegram handle"
          />
        </div>
        <div className="form-group">
          <label>YouTube (optional):</label>
          <input
            type="text"
            className="form-control"
            value={youtube}
            onChange={(e) => setYoutube(e.target.value)}
            placeholder="Enter your YouTube channel"
          />
        </div>
        <div className="form-group">
          <label>Category (optional):</label>
          <input
            type="text"
            className="form-control"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Enter token category"
          />
        </div>
        <div className="form-group">
          <label>Website (optional):</label>
          <input
            type="text"
            className="form-control"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            placeholder="Enter your website URL"
          />
        </div>
        <div className="form-group">
          <label>Description (optional):</label>
          <textarea
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter a description for your token"
          />
        </div>
        <button type="submit" className="btn btn-success">
          Add Extra Features (0.002 ETH)
        </button>
      </form>
    </div>
  );
};

export default AddExtraFeatures;
