import React, { useState, useEffect } from 'react';
import sanityClient from './sanityClient';
import './CryptoNews.css';
import { Link, useParams } from 'react-router-dom';
import CryptoTicker from './cryptoTicker';
import imageUrlBuilder from '@sanity/image-url';
import MiniArticles from './MiniArticles';
import { Helmet } from 'react-helmet';  // React Helmet a meta adatokhoz

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const renderExcerpt = (body) => {
  return body?.map((block) => {
    if (block._type === 'block') {
      return block.children
        .map((child) => (child.text ? child.text : ''))
        .join(' ');
    }
    return '';
  })
  .join(' ')
  .split(' ')
  .slice(0, 30)
  .join(' ') + '...';
};

const renderDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function CryptoNews() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const postsPerPage = 10;
  const { slug } = useParams();

  useEffect(() => {
    const postQuery = slug
      ? `*[_type == "post" && "${slug}" in categories[]->slug.current]{
           _id,
           title,
           slug,
           body,
           mainImage{
             asset->{
               _id,
               url
             }
           },
           publishedAt,
           categories[]->{title, slug},
           metaTitle,         
           metaDescription,  
           keywords           
         }`
      : `*[_type == "post"]{
           _id,
           title,
           slug,
           body,
           mainImage{
             asset->{
               _id,
               url
             }
           },
           publishedAt,
           categories[]->{title, slug},
           metaTitle,        
           metaDescription,  
           keywords           
         } | order(publishedAt desc)`;

    Promise.all([
      sanityClient.fetch(postQuery),
      sanityClient.fetch(`*[_type == "category"]{title, slug}`)
    ])
      .then(([postData, categoryData]) => {
        const uniquePosts = postData.filter((post, index, self) =>
          index === self.findIndex((t) => t._id === post._id)
        );
        setBlogPosts(uniquePosts);
        setCategories(categoryData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data from Sanity:', error);
        setIsLoading(false);
      });
  }, [slug]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const filteredPosts = blogPosts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    renderExcerpt(post.body).toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) {
    return <p>Loading posts...</p>;
  }

  return (
    <div className="crypto-news-container">
      <CryptoTicker />

      <h1 className="crypto-news-title">Crypto News</h1>
      
      {blogPosts.length > 0 && (
        <Helmet>
          <title>{blogPosts[0]?.metaTitle || 'Crypto News'}</title>
          <meta name="description" content={blogPosts[0]?.metaDescription || 'Latest crypto news'} />
          <meta name="keywords" content={blogPosts[0]?.keywords?.join(', ') || 'crypto, blockchain, news'} />
          
          {/* Canonical URL beállítása az adott post slug alapján */}
          <link rel="canonical" href={`https://creatememecoins.com/post/${blogPosts[0].slug.current}`} />
        </Helmet>
      )}


      <div className="search-bar">
        <input
          type="text"
          placeholder="Search posts..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="main-content">
        <MiniArticles blogPosts={blogPosts} />

        <div className="post-list">
          {currentPosts && currentPosts.length > 0 ? (
            currentPosts.map((post, index) => (
              <div key={index} className="blog-post">
                {post.mainImage && post.mainImage.asset && post.mainImage.asset.url && (
                  <img
                    src={urlFor(post.mainImage).width(610).height(300).url()}
                    alt={post.title || 'Post Image'}
                    className="post-main-image"
                    style={{
                      width: '100%',
                      height: '300px',
                      objectFit: 'cover',
                      borderRadius: '8px',
                      marginBottom: '15px'
                    }}
                  />
                )}
                <h2 className="post-title">{post.title || 'Untitled Post'}</h2>

                {post.publishedAt ? (
                  <p className="post-date">
                    Published on: {renderDate(post.publishedAt)}
                  </p>
                ) : (
                  <p className="post-date">Date not available</p>
                )}

                {post.categories && post.categories.length > 0 ? (
                  <p className="post-categories">
                    Categories: {post.categories.map((category) => (
                      <Link key={category?.slug?.current || category?.title} to={`/category/${category.slug?.current || ''}`}>
                        {category?.title}
                      </Link>
                    ))}
                  </p>
                ) : (
                  <p>No categories</p>
                )}

                {post.body && Array.isArray(post.body) ? (
                  <p className="post-excerpt">
                    {renderExcerpt(post.body)}
                  </p>
                ) : (
                  <p>No content available for this post.</p>
                )}

                {post.slug?.current ? (
                  <Link to={`/post/${post.slug.current}`} className="read-more">
                    {`${post.title} - Read More`}
                  </Link>
                ) : (
                  <p>No link available for this post.</p>
                )}

              </div>
            ))
          ) : (
            <p>No blog posts found.</p>
          )}
        </div>

        <aside className="category-sidebar">
          <h2>Popular Categories</h2>
          <ul>
            {categories && categories.length > 0 ? (
              categories.map((category) => (
                <li key={category.slug?.current || category.title}>
                  <Link to={`/category/${category.slug?.current || ''}`}>{category.title}</Link>
                </li>
              ))
            ) : (
              <p>No categories found</p>
            )}
          </ul>
        </aside>
      </div>

      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredPosts.length / postsPerPage) }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => paginate(i + 1)}
            className={`page-button ${currentPage === i + 1 ? 'active' : ''}`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default CryptoNews;
