import React from 'react';
import './Sidebar.css'; // Styles specific to the sidebar
import { Link } from 'react-router-dom'; // Import Link for navigation

function Sidebar({ isOpen, toggleSidebar }) {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-btn" onClick={toggleSidebar}>
        &times;
      </button>
      <h2>Networks</h2>
      <ul>
        <li style={{ display: 'flex', alignItems: 'center' }}>
          {/* A Base elem kattinthatóvá tétele és távolság hozzáadása */}
          <Link to="/" className="network-link" style={{ color: 'blue', marginRight: '10px' }}>
            <span className="network-name">Base</span>
          </Link>
          <span className="status available">Available</span>
        </li>
        <li>
          <span className="network-name">Solana</span>
          <span className="status coming-soon">Coming Soon</span>
        </li>
        <li>
          <span className="network-name">Ethereum</span>
          <span className="status coming-soon">Coming Soon</span>
        </li>
        <li>
          <span className="network-name">BNB</span>
          <span className="status coming-soon">Coming Soon</span>
        </li>
        <li>
          <span className="network-name">Polygon</span>
          <span className="status coming-soon">Coming Soon</span>
        </li>
        <li>
          <Link to="/crypto-news" className="network-link" style={{ color: 'blue' }}>
            <span className="network-name">Crypto News</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
